<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de riesgos antisoborno</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block :to="{name: 'Evaluación de riesgos antisoborno'}" variant="custom">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('017-37001-ERA-RIA','c') == 1" cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalNuevoRiesgo = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo riesgo
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Riesgos antisoborno registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaRiesgos" :fields="cabecera" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('017-37001-ERA-RIA','u') == 1" @click="abrirModalActualizarRiesgo(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('017-37001-ERA-RIA','d') == 1" @click="eliminarRiesgo(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(proceso)="param">
                                    {{comboProcesos.find(x => x.idProceso==param.item.idProceso).nombre}}
                                </template>
                                <template #cell(tipo)="param">
                                    <span v-if="param.item.tipo >= 0 && param.item.tipo <= 6">Bajo</span>
                                    <span v-else-if="param.item.tipo > 6 && param.item.tipo < 16">Medio</span>
                                    <span v-else>Alto</span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalNuevoRiesgo">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo riesgo antisoborno</span>
            </h6>
            <CButtonClose @click="modalNuevoRiesgo = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(nuevoRiesgo)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input v-model="datosRiesgo.nombre" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRiesgo.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRiesgo.idProceso" :options="comboProcesos" @search:blur="blurProceso">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="puesto" rules="required" v-slot="{errors}">
                            <b-form-group label="Puesto de trabajo:" class="mb-2">
                                <v-select :reduce="comboPuestos =>comboPuestos.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRiesgo.idPuestoTrabajo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRiesgo.idPuestoTrabajo" :options="comboPuestos" @search:blur="blurPuestoTrabajo">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de soborno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo de soborno:" class="mb-2">
                                <b-form-select v-model="datosRiesgo.tipoSoborno" :options="comboTipo" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="identificado en que subproceso o actividad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Identificado en que subproceso o actividad:" class="mb-2">
                                <b-form-input v-model="datosRiesgo.identificado" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="Descripción del riesgo / oportunidad" v-slot="validationContext">
                            <b-form-group label="Descripción del riesgo / oportunidad:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosRiesgo.descripcionRiesgoOportunidad" :state="getValidationState(validationContext)" placeholder="Ingrese el detalle"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="detalle del tipo de soborno" v-slot="validationContext">
                            <b-form-group label="Detalle del tipo de soborno:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosRiesgo.detalleTipoSoborno" :state="getValidationState(validationContext)" placeholder="Ingrese el detalle"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="circunstancias o en que momento se produce" v-slot="validationContext">
                            <b-form-group label="Circunstancias o en que momento se produce:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosRiesgo.circunstanciasActividad" :state="getValidationState(validationContext)" placeholder="Ingrese las circunstancias o actividad"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="8" class="mt-2">
                        <span class="h6">Promotor del soborno:</span>
                    </b-col>
                    <b-col md="4" class="mt-2">
                        <CButton size="sm" color="dark" class="float-right" @click="agregarPromotor()">
                            <i class="fas fa-plus fa-sm"></i> Agregar promotor
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="datosRiesgo.listaPromotor" :fields="campoPromotor" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                            <template v-slot:cell(nombrePuesto)="row">
                                <b-form-input placeholder="Ingrese el nombre del puesto" v-model="row.item.nombrePuesto"></b-form-input>
                            </template>
                            <template v-slot:cell(proceso)="row">
                                <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" v-model="row.item.proceso" :options="comboProcesos">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                            </template>
                            <template #cell(acciones)="param">
                                <b-button v-if="datosRiesgo.listaPromotor.length>1" @click="eliminarPromotor(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>

                        </b-table>
                    </b-col>
                    <b-col md="8">
                        <span class="h6">Promovido del soborno:</span>
                    </b-col>
                    <b-col md="4">
                        <CButton size="sm" color="dark" class="float-right" @click="agregarPromovido()">
                            <i class="fas fa-plus fa-sm"></i> Agregar promovido
                        </CButton>
                    </b-col>
                    <b-col md="12" class="my-2">
                        <b-table :items="datosRiesgo.listaPromovido" :fields="campoPromotor" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                            <template v-slot:cell(nombrePuesto)="row">
                                <b-form-input placeholder="Ingrese el nombre del puesto" v-model="row.item.nombrePuesto"></b-form-input>
                            </template>
                            <template v-slot:cell(proceso)="row">
                                <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" v-model="row.item.proceso" :options="comboProcesos">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                            </template>
                            <template #cell(acciones)="param">
                                <b-button v-if="datosRiesgo.listaPromovido.length>1" @click="eliminarPromovido(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>

                        </b-table>
                    </b-col>
                    <b-col md="12" class="my-2">
                        <span class="h6">Primera evaluación:</span>
                    </b-col>
                    <b-col md="12" class="my-2">
                        <span>Controles actuales:</span>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="control" rules="required" v-slot="{errors}">
                            <b-form-group label="Control:" class="mb-2">
                                <v-select @input="obtenerSubControles" :reduce="comboControles =>comboControles.idControlAntisoborno" label="descripcionControl" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRiesgo.idControl , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRiesgo.idControl" :options="comboControles" @search:blur="blurControles">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Controles antisoborno'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="subcontroles" rules="required" v-slot="{errors}">
                            <b-form-group label="Sub controles:" class="mb-2">
                                <v-select multiple :reduce="comboSubControles =>comboSubControles.idSubControl" label="subControl" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRiesgo.idSubControl.length>0 , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRiesgo.idSubControl" :options="comboSubControles" @search:blur="blurSubControles">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Controles antisoborno'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="probabilidad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Probabilidad:" class="mb-2">
                                <b-form-select v-on:change="calcularTipoRiesgo()" v-model="datosRiesgo.probabilidad" :options="listaProbabilidad" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="impacto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Impacto:" class="mb-2">
                                <b-form-select v-on:change="calcularTipoRiesgo()" v-model="datosRiesgo.impacto" :options="listaImpacto" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="tipo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-input-group-text :style="`background:${datosRiesgo.tipoTexto == 'Alto' ? '#e55353;' : datosRiesgo.tipoTexto == 'Medio' ? '#f9b115;' : datosRiesgo.tipoTexto == 'Bajo' ? '#2eb85c;' : '' }`">
                                        </b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input class="disabled-white" disabled v-model="datosRiesgo.tipoTexto" :state="getValidationState(validationContext)" placeholder=""></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalNuevoRiesgo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalActualizarRiesgo">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Detalle del riesgo </span>
            </h6>
            <CButtonClose @click="modalActualizarRiesgo = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarRiesgo)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input v-model="datosRiesgoActualizar.nombre" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRiesgoActualizar.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRiesgoActualizar.idProceso" :options="comboProcesos" @search:blur="blurProceso">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="puesto" rules="required" v-slot="{errors}">
                            <b-form-group label="Puesto de trabajo:" class="mb-2">
                                <v-select :reduce="comboPuestos =>comboPuestos.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRiesgoActualizar.idPuestoTrabajo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRiesgoActualizar.idPuestoTrabajo" :options="comboPuestos" @search:blur="blurModificarPuestoTrabajo">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de soborno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo de soborno:" class="mb-2">
                                <b-form-select v-model="datosRiesgoActualizar.tipoSoborno" :options="comboTipo" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="identificado en que subproceso o actividad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Identificado en que subproceso o actividad:" class="mb-2">
                                <b-form-input v-model="datosRiesgoActualizar.identificado" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="Descripción del riesgo / oportunidad" v-slot="validationContext">
                            <b-form-group label="Descripción del riesgo / oportunidad:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosRiesgoActualizar.descripcionRiesgoOportunidad" :state="getValidationState(validationContext)" placeholder="Ingrese el detalle"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="detalle del tipo de soborno" v-slot="validationContext">
                            <b-form-group label="Detalle del tipo de soborno:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosRiesgoActualizar.detalleTipoSoborno" :state="getValidationState(validationContext)" placeholder="Ingrese el detalle"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="circunstancias o en que momento se produce" v-slot="validationContext">
                            <b-form-group label="Circunstancias o en que momento se produce:" class="mb-2">
                                <b-form-textarea rows="2" max-rows="6" v-model="datosRiesgoActualizar.circunstanciasActividad" :state="getValidationState(validationContext)" placeholder="Ingrese las circunstancias o actividad"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="8" class="mt-2">
                        <span class="h6">Promotor del soborno:</span>
                    </b-col>
                    <b-col md="4" class="mt-2">
                        <CButton size="sm" color="dark" class="float-right" @click="agregarPromotor()">
                            <i class="fas fa-plus fa-sm"></i> Agregar promotor
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="datosRiesgoActualizar.listaPromotor" :fields="campoPromotor" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                            <template v-slot:cell(nombrePuesto)="row">
                                <b-form-input placeholder="Ingrese el nombre del puesto" v-model="row.item.nombrePuesto"></b-form-input>
                            </template>
                            <template v-slot:cell(proceso)="row">
                                <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" v-model="row.item.proceso" :options="comboProcesos">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                            </template>
                            <template #cell(acciones)="param">
                                <b-button v-if="datosRiesgoActualizar.listaPromotor.length>1" @click="eliminarPromotor(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>

                        </b-table>
                    </b-col>
                    <b-col md="8">
                        <span class="h6">Promovido del soborno:</span>
                    </b-col>
                    <b-col md="4">
                        <CButton size="sm" color="dark" class="float-right" @click="agregarPromovido()">
                            <i class="fas fa-plus fa-sm"></i> Agregar promovido
                        </CButton>
                    </b-col>
                    <b-col md="12" class="my-2">
                        <b-table :items="datosRiesgoActualizar.listaPromovido" :fields="campoPromotor" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                            <template v-slot:cell(nombrePuesto)="row">
                                <b-form-input placeholder="Ingrese el nombre del puesto" v-model="row.item.nombrePuesto"></b-form-input>
                            </template>
                            <template v-slot:cell(proceso)="row">
                                <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" v-model="row.item.proceso" :options="comboProcesos">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                            </template>
                            <template #cell(acciones)="param">
                                <b-button v-if="datosRiesgoActualizar.listaPromovido.length>1" @click="eliminarPromovido(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>

                        </b-table>
                    </b-col>
                    
                    <b-col md="12" class="my-2">
                        <span class="h6">Primera evaluación:</span>
                    </b-col>
                    <b-col md="12" class="my-2">
                        <span>Controles actuales:</span>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="control" rules="required" v-slot="{errors}">
                            <b-form-group label="Control:" class="mb-2">
                                <v-select @input="obtenerSubControles" :reduce="comboControles =>comboControles.idControlAntisoborno" label="descripcionControl" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRiesgoActualizar.idControl , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRiesgoActualizar.idControl" :options="comboControles" @search:blur="blurModificarControles">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Controles antisoborno'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="subcontroles" rules="required" v-slot="{errors}">
                            <b-form-group label="Sub controles:" class="mb-2">
                                <v-select multiple :reduce="comboSubControles =>comboSubControles.idSubControl" label="subControl" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRiesgoActualizar.idSubControl.length>0 , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRiesgoActualizar.idSubControl" :options="comboSubControles" @search:blur="blurModificarSubControles">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Controles antisoborno'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="probabilidad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Probabilidad:" class="mb-2">
                                <b-form-select v-on:change="calcularTipoRiesgoActualizr()" v-model="datosRiesgoActualizar.probabilidad" :options="listaProbabilidad" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="impacto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Impacto:" class="mb-2">
                                <b-form-select v-on:change="calcularTipoRiesgoActualizr()" v-model="datosRiesgoActualizar.impacto" :options="listaImpacto" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="tipo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-input-group-text :style="`background:${datosRiesgoActualizar.tipoTexto == 'Alto' ? '#e55353;' : datosRiesgoActualizar.tipoTexto == 'Medio' ? '#f9b115;' : datosRiesgoActualizar.tipoTexto == 'Bajo' ? '#2eb85c;' : '' }`">
                                        </b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input class="disabled-white" disabled v-model="datosRiesgoActualizar.tipoTexto" :state="getValidationState(validationContext)" placeholder=""></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarRiesgo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalNuevoRiesgo: false,
            modalActualizarRiesgo: false,

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "proceso",
                    label: "Proceso",
                    class: "text-center",
                },
                {
                    key: "tipo",
                    label: "Tipo",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            campoPromotor: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombrePuesto",
                    label: "Nombre del puesto",
                    class: "text-center",
                },
                {
                    key: "proceso",
                    label: "Proceso (opcional)",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: '',
                    class: "text-center"
                }
            ],
            listaRiesgos: [],
            comboProcesos: [],
            datosRiesgo: {
                idCliente: '',
                nombre: '',
                idProceso: null,
                idPuestoTrabajo: null,
                detalleTipoSoborno: '',
                circunstanciasActividad: '',
                tipoSoborno: null,
                identificado: '',
                descripcionRiesgoOportunidad: '',
                listaPromotor: [{
                    nombrePuesto: '',
                    proceso: '',
                }],
                listaPromovido: [{
                    nombrePuesto: '',
                    proceso: '',
                }],
                idControl: null,
                idSubControl: [],
                probabilidad: null,
                                aspectosEvaluar: '',
                impacto: null,
                tipo: '',
                tipoTexto: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            },
            datosRiesgoActualizar: {
                idRiesgo: '',
                idCliente: '',
                nombre: '',
                idProceso: null,
                idPuestoTrabajo: null,
                detalleTipoSoborno: '',
                circunstanciasActividad: '',
                tipoSoborno: null,
                identificado: '',
                descripcionRiesgoOportunidad: '',
                listaPromotor: [],
                listaPromovido: [],
                idControl: null,
                idSubControl: [],
                probabilidad: null,
                aspectosEvaluar: '',
                impacto: null,
                tipo: '',
                tipoTexto: '',
                ejercicio: '',
            },
            comboTipo: [{
                    value: 1,
                    text: 'Soborno interno'
                },
                {
                    value: 2,
                    text: 'Soborno externo'
                }
            ],
            listaProbabilidad: [{
                    value: 1,
                    text: 'Seria excepcional'
                },
                {
                    value: 2,
                    text: 'Es raro que suceda'
                },
                {
                    value: 3,
                    text: 'Es posible'
                },
                {
                    value: 4,
                    text: 'Muy probable'
                },
            ],
            listaImpacto: [{
                    value: 1,
                    text: 'Insignificante'
                },
                {
                    value: 2,
                    text: 'Pequeño'
                },
                {
                    value: 3,
                    text: 'Moderado'
                },
                {
                    value: 4,
                    text: 'Grande'
                },
                {
                    value: 5,
                    text: 'Catastrófico'
                },
            ],
            disabled: false,
            comboPuestos: [],
            comboControles: [],
            comboSubControles: [],
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },
        blurModificarProceso() {
            this.computedForm2.refs.proceso.validate();
        },
        blurPuestoTrabajo() {
            this.computedForm.refs.puesto.validate();
        },
        blurModificarPuestoTrabajo() {
            this.computedForm2.refs.puesto.validate();
        },
        blurControles() {
            this.computedForm.refs.control.validate();
        },
        blurModificarControles() {
            this.computedForm2.refs.control.validate();
        },
        blurSubControles() {
            this.computedForm.refs.subcontroles.validate();
        },
        blurModificarSubControles() {
            this.computedForm2.refs.subcontroles.validate();
        },
        agregarPromotor() {
            let me = this;
            me.datosRiesgo.listaPromotor.push({
                nombrePuesto: '',
                proceso: '',
            })
        },
        eliminarPromotor(param) {
            let me = this;
            me.datosRiesgo.listaPromotor.splice(param.index, 1)

        },
        agregarPromovido() {
            let me = this;
            me.datosRiesgo.listaPromovido.push({
                nombrePuesto: '',
                proceso: '',
            })
        },
        eliminarPromovido(param) {
            let me = this;
            me.datosRiesgo.listaPromovido.splice(param.index, 1)

        },
        agregarPromotorActualizar() {
            let me = this;
            me.datosRiesgoActualizar.listaPromotor.push({
                nombrePuesto: '',
                proceso: '',
            })
        },
        eliminarPromotorActualizar(param) {
            let me = this;
            me.datosRiesgoActualizar.listaPromotor.splice(param.index, 1)

        },
        agregarPromovidoActualizar() {
            let me = this;
            me.datosRiesgoActualizar.listaPromovido.push({
                nombrePuesto: '',
                proceso: '',
            })
        },
        eliminarPromovidoActualizar(param) {
            let me = this;
            me.datosRiesgoActualizar.listaPromovido.splice(param.index, 1)

        },
        calcularTipoRiesgo() {
            let me = this;
            let probabilidad = me.datosRiesgo.probabilidad != '' ? parseFloat(me.datosRiesgo.probabilidad) : 0;
            let impacto = me.datosRiesgo.impacto != '' ? parseFloat(me.datosRiesgo.impacto) : 0;
            let result = probabilidad * impacto;
            me.datosRiesgo.tipo = result;
            me.datosRiesgo.tipoTexto = result >= 0 && result <= 6 ? 'Bajo' : result > 6 && result < 16 ? 'Medio' : result >= 16 ? 'Alto' : '';
        },
        calcularTipoRiesgoActualizr() {
            let me = this;
            let probabilidad = me.datosRiesgoActualizar.probabilidad != '' ? parseFloat(me.datosRiesgoActualizar.probabilidad) : 0;
            let impacto = me.datosRiesgoActualizar.impacto != '' ? parseFloat(me.datosRiesgoActualizar.impacto) : 0;
            let result = probabilidad * impacto;
            me.datosRiesgoActualizar.tipo = result;
            me.datosRiesgoActualizar.tipoTexto = result >= 0 && result <= 6 ? 'Bajo' : result > 6 && result < 16 ? 'Medio' : result >= 16 ? 'Alto' : '';
        },
        obtenerSubControles(e){
            this.datosRiesgo.idSubControl = [];
            this.datosRiesgoActualizar.idSubControl = [];
            let subControles = JSON.parse(this.comboControles.find(x => x.idControlAntisoborno==e).listaControlesDetalle);
            this.comboSubControles = subControles;
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosRiesgo.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboPuestos = response.data;
                })
                .catch(function (error) {
                    console.log(error)
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarRiesgos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-riesgos-antisoborno-sga", {
                        params: {
                            idCliente: me.datosRiesgo.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    me.listaRiesgos = response.data
                    me.totalRows = me.listaRiesgos.length;
                })
                .catch(function (error) {
                    console.log(error)
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosRiesgo.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProcesos = response.data
                    me.listarRiesgos()
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarControlesAntisoborno() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-controles-antisoborno-sga", {
                        params: {
                            idCliente: me.datosRiesgo.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    for (const i in response.data) {
                        me.comboControles.push(
                            {
                                idControlAntisoborno: response.data[i].idControlAntisoborno,
                                descripcionControl: response.data[i].numeroControl+' - '+response.data[i].descripcionControl,
                                listaControlesDetalle: response.data[i].listaControlesDetalle,
                            }
                        )
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        nuevoRiesgo() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-riesgo-antisoborno-sga",
                    me.datosRiesgo, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarRiesgos();
                    me.modalNuevoRiesgo = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarRiesgo() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-riesgo-antisoborno-sga",
                    me.datosRiesgoActualizar, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarRiesgos();
                    me.modalActualizarRiesgo = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalActualizarRiesgo(param) {
            let me = this;

            me.datosRiesgoActualizar.idRiesgo = param.item.idRiesgo;
            me.datosRiesgoActualizar.idCliente = param.item.idCliente;
            me.datosRiesgoActualizar.nombre = param.item.nombre;
            me.datosRiesgoActualizar.idProceso = param.item.idProceso;
            me.datosRiesgoActualizar.idPuestoTrabajo = param.item.idPuestoTrabajo;
            me.datosRiesgoActualizar.detalleTipoSoborno = param.item.detalleTipoSoborno;
            me.datosRiesgoActualizar.circunstanciasActividad = param.item.circunstanciasActividad;
            me.datosRiesgoActualizar.tipoSoborno = param.item.tipoSoborno;
            me.datosRiesgoActualizar.identificado = param.item.identificado;
            me.datosRiesgoActualizar.aspectosEvaluar = param.item.aspectosEvaluar ? param.item.aspectosEvaluar : '';
            me.datosRiesgoActualizar.descripcionRiesgoOportunidad = param.item.descripcionRiesgoOportunidad;
            me.datosRiesgoActualizar.listaPromotor = JSON.parse(param.item.listaPromotor);
            me.datosRiesgoActualizar.listaPromovido = JSON.parse(param.item.listaPromovido);
            me.datosRiesgoActualizar.idControl = param.item.idControl;
            me.obtenerSubControles(me.datosRiesgoActualizar.idControl);
            me.datosRiesgoActualizar.idSubControl = JSON.parse(param.item.idSubControl);
            me.datosRiesgoActualizar.probabilidad = param.item.probabilidad;
            me.datosRiesgoActualizar.impacto = param.item.impacto;
            me.datosRiesgoActualizar.tipo = param.item.tipo;
            me.datosRiesgoActualizar.tipoTexto = parseInt(me.datosRiesgoActualizar.tipo) >= 0 && parseInt(me.datosRiesgoActualizar.tipo) <= 6 ? 'Bajo' : parseInt(me.datosRiesgoActualizar.tipo) > 6 && parseInt(me.datosRiesgoActualizar.tipo) < 16 ? 'Medio' : parseInt(me.datosRiesgoActualizar.tipo) >= 16 ? 'Alto' : '';
            me.datosRiesgoActualizar.ejercicio = param.item.ejercicio;

            me.modalActualizarRiesgo = true;
        },
        resetModalNuevoRiesgo() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosRiesgo.listaPromotor = [];
            this.datosRiesgo.nombre = '';
            this.datosRiesgo.idProceso = null;
            this.datosRiesgo.idPuestoTrabajo = null;
            this.datosRiesgo.detalleTipoSoborno = '';
            this.datosRiesgo.circunstanciasActividad = null;
            this.datosRiesgo.tipoSoborno = null;
            this.datosRiesgo.identificado = '';
            this.datosRiesgo.aspectosEvaluar = '';
            this.datosRiesgo.descripcionRiesgoOportunidad = '';
            this.datosRiesgo.probabilidad = null;
            this.datosRiesgo.impacto = null;
            this.datosRiesgo.tipo = '';
            this.datosRiesgo.tipoTexto = '';
            this.datosRiesgo.listaPromotor = [{
                nombrePuesto: '',
                proceso: '',
            }];
            this.datosRiesgo.listaPromovido = [{
                nombrePuesto: '',
                proceso: '',
            }];

            this.datosRiesgo.idControl = null;
            this.datosRiesgo.idSubControl = [];
            
        },
        resetModalActualizarRiesgo() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },
        eliminarRiesgo(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el riesgo?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-riesgo-antisoborno-sga", {
                            idRiesgo: param.item.idRiesgo,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarRiesgos();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalNuevoRiesgo: function (val) {
            if (val == false) {
                this.resetModalNuevoRiesgo();
            }
        },
        modalActualizarRiesgo: function (val) {
            if (val == false) {
                this.resetModalActualizarRiesgo();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosRiesgo.idCliente = user.uidClient;
            //this.listarRiesgos();
            this.listarProcesos();
            this.listarPuestosTrabajo();
            this.listarControlesAntisoborno();
        }

    }

}
</script>
